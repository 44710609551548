import { Router } from "aurelia-router";
import { autoinject } from "aurelia-dependency-injection";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { I18N } from "aurelia-i18n";

@connectTo()
@autoinject
export class Surprise {
  private displayError: boolean = false;
  private errorMessage: string;
  private success: boolean = false;
  private isLoading: boolean = false;

  constructor(private router: Router, private store: Store<State>, private state: State,
   private paymailWalletHttpclient : PaymailWalletClient, private i18n: I18N) {
    
   }

   bind() {
    setTimeout(() => (this.goToHome()), 3000);
   }

    async postPaymailForGift(walletId) {

    try {
      this.isLoading = true;
      let request = await this.paymailWalletHttpclient.fetch(
        "/wallet/" + walletId + "/promo-gift"
      );
      let is200Ok = await checkResponseStatus(request);
      let response = await is200Ok.json();
      if (response) {
        this.success = true;
        this.isLoading = false;
        setTimeout(() => (this.goToHome()), 3000);
      }
      console.log(response);
    }
    catch (e) {
      console.log(e);
      this.displayError = true;
      this.isLoading = false;
      this.errorMessage = this.i18n.tr("error.surprise.already_received")    //"You already received the gift! Share the QR Code with your friends!"
    }

  }

  goToChooseWallet() {
    this.store.dispatch("set.make_payment.mk", true);
    this.router.navigateToRoute("choose_wallet");
  }

  goToHome() {
    this.router.navigateToRoute("home");
  }
}
