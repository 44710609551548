// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./comment.scss"></require>
  <require from="components/transaction-component/transaction-component"></require>
  <require from="components/transaction-bsv-component/transaction-bsv-component"></require>
  <require from="components/loader-ring/loader-ring"></require>
  
  <section id="transaction_comments" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 t="home.transactions.transactions1"></h1>
    </div>
    <!-- Loading -->
    <div class="flex-grow" if.bind="loading"></div>
    <loader-ring if.bind="loading"></loader-ring>
    <div class="flex-grow" if.bind="loading"></div>

    <!--Transaction-component-->
    <transaction-component if.bind="!loading" is-search-enabled.bind="true" loading.bind="loading"
      transactions.bind="state.wallets[state.selectedAccountIndex].transactions" limit.bind="false">
    </transaction-component>
  </section>
</template>
`;
// Exports
export default code;