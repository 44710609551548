import { SingletonService } from "singleton";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import ENDPOINTS from "endpoints";
import { BankaService } from "services/banka.service";
import { WalletsManager, WalletInfo } from "services/WalletManager.service";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { connectTo } from "aurelia-store";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { State } from "state";
import { I18N } from "aurelia-i18n";

@connectTo()
@autoinject
export class Transactions {
  private transactions: Array<IGenericTransaction>;
  private search_term: string = "";
  private dateFilter: string = "all";
  private isDropDown: boolean = false;
  private formattedTransactions: Array<{ dateLabel: string, transactions: IGenericTransaction[] }> = [];
  private loading: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private singleton: SingletonService,
    private yapilyhttpclient: YapilyHttpClient,
    private bankaService: BankaService,
    private walletManager: WalletsManager,
    private state: State,
    private i18n: I18N
  ) {}

  async bind() {
    if (this.state.wallets[this.state.selectedAccountIndex] === undefined) {
        await this.delay();
    }
    this.checkTransactions();
    
  }

  async delay() {
      return new Promise(resolve => {
          setTimeout(() => {
              resolve(this.checkTransactions());
          }, 3000);
      });
  }

  checkTransactions() {
      this.transactions = this.state.wallets[this.state.selectedAccountIndex]?.transactions || [];
      console.log("Transactions loaded:", this.transactions);
      this.updateFormattedTransactions();
  }

  updateFormattedTransactions() {
    console.log("Mise à jour des transactions formatées.");
    this.formattedTransactions = this.search_results_transactions;
    console.log("Transactions formatées :", this.formattedTransactions);
  }

  @computedFrom("transactions", "search_term", "dateFilter")
  get search_results_transactions(): Array<{ dateLabel: string, transactions: Array<IGenericTransaction> }> {
    if (!this.transactions || this.transactions.length === 0) {
        console.log("No transactions found.");
        return [];
    }

    let filteredTransactions = this.transactions;

    // Filtrage par date
    const dateLimit = this.getDateLimit(this.dateFilter);
    if (dateLimit) {
        filteredTransactions = filteredTransactions.filter(item => new Date(item.createdAt) >= dateLimit);
    }

    // Filtrage par terme de recherche
    if (this.search_term) {
        const searchTerm = this.search_term.toLowerCase();
        filteredTransactions = filteredTransactions.filter((item: IGenericTransaction) =>
            item.createdAt.toString().toLowerCase().includes(searchTerm) ||
            (item.senderNote ?? "").toLowerCase().includes(searchTerm) ||
            item.amount.toString().toLowerCase().includes(searchTerm) ||
            item.senderIdentification.toLowerCase().includes(searchTerm) ||
            item.senderName.toLowerCase().includes(searchTerm)
        );
    }

    // Regroupement par date
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const formatted: Array<{ dateLabel: string, transactions: Array<IGenericTransaction> }> = [];
    const transactionMap: { [key: string]: Array<IGenericTransaction> } = {};

    filteredTransactions.forEach(transaction => {
        const transactionDate = new Date(transaction.createdAt);
        const transactionDay = new Date(transactionDate.getFullYear(), transactionDate.getMonth(), transactionDate.getDate());

        let dateLabel: string;
        if (transactionDay.getTime() === today.setHours(0, 0, 0, 0)) {
            dateLabel = this.i18n.tr("home.transactions.today");
        } else if (transactionDay.getTime() === yesterday.setHours(0, 0, 0, 0)) {
            dateLabel = this.i18n.tr("home.transactions.yesterday");
        } else {
            dateLabel = transactionDate.toLocaleDateString();
        }

        if (!transactionMap[dateLabel]) {
            transactionMap[dateLabel] = [];
        }
        transactionMap[dateLabel].push(transaction);
    });

    for (const [dateLabel, transactions] of Object.entries(transactionMap)) {
        formatted.push({ dateLabel, transactions });
    }

    console.log("Formatted Transactions:", formatted); // Pour le débogage
    return formatted;
  }

  getDateLimit(filter: string): Date | null {
    const now = new Date();
    switch (filter) {
      case "7d":
        return new Date(now.setDate(now.getDate() - 7));
      case "30d":
        return new Date(now.setDate(now.getDate() - 30));
      case "120d":
        return new Date(now.setDate(now.getDate() - 120));
      case "365d":
        return new Date(now.setFullYear(now.getFullYear() - 1));
      case "all":
      default:
        return null;
    }
  }

  filterTransactions(days: number | string) {
    switch (days) {
      case 7:
        this.dateFilter = "7d";
        break;
      case 30:
        this.dateFilter = "30d";
        break;
      case 120:
        this.dateFilter = "120d";
        break;
      case 365:
        this.dateFilter = "365d";
        break;
      case "all":
      default:
        this.dateFilter = "all";
        break;
    }
    this.isDropDown = false;
  }

  download() {
    const transactionsByDate = this.search_results_transactions;
    if (transactionsByDate.length === 0) {
      alert("No transactions to download.");
      return;
    }
  
    const accountIdentification = this.state.wallets[this.state.selectedAccountIndex].identification;
    const isBlockchain = this.state.wallets[this.state.selectedAccountIndex].isBlockchain;
    const headers = `Coming from account: ${accountIdentification}\nCreated At, Currency, Amount, Sender Name, Sender Identification, Sender Note${isBlockchain ? ", Transaction ID" : ""}\n`;
    
    const csvData = transactionsByDate.map(group => 
      group.transactions.map(transaction => {
        // Si blockchain, ajoute le Transaction ID, sinon, on ignore
        return isBlockchain
          ? `${transaction.createdAt.toISOString()}, ${transaction.currency}, ${transaction.amount}, ${transaction.senderName}, ${transaction.senderIdentification}, ${transaction.senderNote}, ${transaction._id}`
          : `${transaction.createdAt.toISOString()}, ${transaction.currency}, ${transaction.amount}, ${transaction.senderName}, ${transaction.senderIdentification}, ${transaction.senderNote}`;
      }).join("\n")
    ).join("\n");
  
    const csvContent = headers + csvData;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
  
    link.setAttribute('href', url);
    link.setAttribute('download', 'transactions.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}