// Module
var code = `<template>
    <require from="./surprise.scss"></require>
    <require from="../../../components/loader-ring/loader-ring"></require>
    <require from="../../../components/animated_checkmark/animated_checkmark"></require>
    <require from="../../../components/number-format-bsv"></require>
    <section id="surprise" class="flex-column">
      <div class="flex-grow"></div>
      <p i18n="home.surprise.thank_you"></p>
      <!-- <div class="img_container">
        <img src="/static/img/gift.png"/>
      </div>
      <div class="checkmark_container" if.bind="success">
        <animated-checkmark if.bind="success"></animated-checkmark>
      </div>
      <div class="flex-grow"></div> -->
      <!-- <div class="account_container listing flex-column" if.bind="!success">
        <div class="object flex-row" click.delegate="goToChooseWallet()" if.bind="state.makePayment.sender">
          <img src="/static/img/currency/bitcoin/bitcoin_account_white.png" if.bind="state.makePayment.sender.isBlockchain"/>
          <div class="texte flex-column" if.bind="!loader">
            <p class="title">\${ state.makePayment.sender.name }</p>
            <p class="desc">\${ state.makePayment.sender.identification }</p>
            <p class="desc">
              \${state.makePayment.sender.balance  | numberFormatBsv:localeForCurrency}
            </p>
          </div>
        </div>
        <div class="object flex-row" click.delegate="goToChooseWallet()" if.bind="!state.makePayment.sender">
          <img src="/static/img/info.png"/>
          <div class="texte flex-column" if.bind="!loader">
            <p class="title" t="payments.no_selected_account"></p>
            <p class="desc" t="payments.add_one"></p>
          </div>
          <div class="flex-grow"></div>
        </div>
      </div> -->
      <!-- <div class="flex-grow" if.bind="displayError"></div>
      <div class="error" if.bind="displayError">
        <span>\${errorMessage}</span>
      </div> -->
      <div class="flex-grow"></div>
      <!-- <button click.delegate="postPaymailForGift(state.makePayment.sender.id)" class="btn-fresh btn-fresh-primary" if.bind="!success" disabled.bind="!state.makePayment.sender || isLoading">
        <span if.bind="!isLoading">Valider</span>
        <loader-ring if.bind="isLoading"></loader-ring>
      </button>
      <div class="spacer-32"></div> -->
    </section>
  </template>
  `;
// Exports
export default code;