// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/welcome/musicplay.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/welcome/experience.png", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("/static/welcome/blockchain.png", import.meta.url);
var ___HTML_LOADER_IMPORT_3___ = new URL("/static/welcome/design_phone.png", import.meta.url);
var ___HTML_LOADER_IMPORT_4___ = new URL("/static/welcome/svg/search.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_5___ = new URL("/static/img/blue_check.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_6___ = new URL("/static/welcome/banks_list.png", import.meta.url);
var ___HTML_LOADER_IMPORT_7___ = new URL("/static/welcome/welcome_security.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_8___ = new URL("/static/welcome/welcome_notification.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_9___ = new URL("/static/welcome/welcome_bank.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_10___ = new URL("/static/welcome/welcome_wallet.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_11___ = new URL("/static/welcome/welcome_qrcode.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_12___ = new URL("/static/welcome/welcome_savings.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_13___ = new URL("/static/welcome/welcome_topup.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_14___ = new URL("/static/welcome/welcome_UX.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_15___ = new URL("/static/welcome/pricing_checkbox.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_16___ = new URL("/static/welcome/pwa_hand.png", import.meta.url);
// Module
var code = `<template>
  <require from="./welcome.scss"></require>
  <require from="../../../components/nav-bar/nav-bar"></require>
  <require from="../../../components/footer-bottom/footer-bottom"></require>
  <require from="../../../components/languages/languages"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <section id="welcome" class="au-animate">
    <nav-bar ></nav-bar>

    <header id="header" class="header">
      <div class="intro">
        <div class="circle"></div> 
        <h2 t="home.welcome.welcome_subtitle"></h2>
        <h1>
          <span class="master_your" t="home.welcome.control_your"></span>
          <div class="ms-slider">
            <ul class="ms-slider__words">
              <li class="ms-slider__word au-target banks" t="home.welcome.accounts">comptes bancaires</li>
              <li class="ms-slider__word au-target assets" t="home.welcome.assets">actifs numériques</li>
              <li class="ms-slider__word au-target transactions" t="home.welcome.transactions">transactions</li>
              <li class="ms-slider__word au-target payments" t="home.welcome.wealth">paiements</li>
              <li class="ms-slider__word au-target bitcoin" t="bitcoin.bitcoinsv">Bitcoin SV</li>
              <li class="ms-slider__word au-target savings" t="home.welcome.savings">économies</li>
              <li class="ms-slider__word au-target expenses" t="home.welcome.expenses">dépenses</li>
              <li class="ms-slider__word au-target banks" t="home.welcome.accounts">comptes bancaires</li>
            </ul>
          </div>
        </h1>
        <p t="home.welcome.welcome_long_desc" class="mybanka_description"></p>
        <div class="action">
          <button id="welcome.button.get_started.1" class="join btn-fresh btn-fresh-primary" t="home.welcome.get_started" click.delegate="getMobileOperatingSystem()"></button>
          <button id="welcome.button.watch" class="video btn-fresh btn-fresh-secondary" click.delegate="watch()"><img src="${___HTML_LOADER_IMPORT_0___}"> <span t="home.welcome.watch_video"></span></button>
        </div>
      </div>
      <div class="connecting_people"> 
        <div class="circle"></div> 
        <div class="outer_circle">
          <div class="inner_circle">
            <div class="logo">  
            </div>
          </div>
        </div>
      </div>
    </header>

    <section id="openbanking">
      <div class="left_right_container flex-row">
        <div id="openbanking-left" class="openbanking-left">
            <img src="${___HTML_LOADER_IMPORT_1___}"   class="" alt="welcome_experience">
        </div>
        <div id="openbanking-right" class="openbanking-right">
          <h2 t="home.welcome.openbanking.open_banking"></h2>
          <h1>
              <span t="home.welcome.openbanking.open_banking_title"></span>
          </h1>
          <p class="mybanka_description">
            <span t="home.welcome.openbanking.open_banking_desc"></span><br>
            <span t="home.welcome.openbanking.open_banking_desc_2"></span>
          </p>
          <div>
            <ul>
              <li t="home.welcome.openbanking.features.balances"></li>
              <li t="home.welcome.openbanking.features.transactions"></li>
              <li t="home.welcome.openbanking.features.payments"></li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section id="blockchain">
      <div class="left_right_container flex-row">
        <div id="blockchain-left" class="blockchain-left">
          <div class="circle"></div> 
          <h2 t="home.welcome.blockchain.subtitle"></h2>
          <h1>
              <span t="home.welcome.blockchain.title"></span>
          </h1>
          <p class="mybanka_description" t="home.welcome.blockchain.description">
          </p>
          <div>
            <ul>
              <li t="home.welcome.blockchain.features.wallet"></li>
              <li t="home.welcome.blockchain.features.send_receie"></li>
              <li t="home.welcome.blockchain.features.top_up"></li>
              <li t="home.welcome.blockchain.features.nft_collection"></li>
            </ul>
          </div>
        </div>
        <div id="blockchain-right" class="blockchain-right">
          <div class="circle"></div> 
          <img src="${___HTML_LOADER_IMPORT_2___}"   class="" alt="welcome_experience">
        </div>
      </div>
    </section>

    <section id="financial_experience">
      <div class="left_right_container flex-row">
        <div id="design-left" class="design-left">
          <img src="${___HTML_LOADER_IMPORT_3___}"   class="" alt="welcome_experience">
        </div>
        <div id="design-right" class="design-right">
          <h2 t="home.welcome.financial_experience.subtitle"></h2>
          <h1>
              <span t="home.welcome.financial_experience.title"></span>
          </h1>
          <p class="mybanka_description" t="home.welcome.financial_experience.description">
          </p>
        </div>
      </div>
    </section>

    <section id="bank">
      <div class="left_right_container flex-row">
        <div id="bank-left" class="bank-left">
          <div class="circle"></div>
            <h2 t="home.welcome.covered.subtitle"></h2>
            <h1 t="home.welcome.covered.title"></h1>
            <p class="mybanka_description" t="home.welcome.covered.description">
            </p>
          <form id="search_banks_form" class="search_bar" submit.trigger="searchAvailableBanks()" role="form">
            <img src="${___HTML_LOADER_IMPORT_4___}" alt="">
            <input id="welcome.input.covered_search" t="[placeholder]home.welcome.covered.search_placeholder" value.bind="search_term_bank">
            <button id="welcome.button.covered_search" class="btn-fresh btn-fresh-primary" t="home.welcome.covered.search" type="submit" form="search_banks_form"></button>
          </form>

          <div class="search_bank_results">
            <div class="bank" repeat.for="bank of searchResultsBanksSupported">
              <img class="bank" src.bind="bank.media[0].source" alt.bind="bank.fullName" title.bind="bank.fullName">
              <img class="checkmark" src="${___HTML_LOADER_IMPORT_5___}" alt.bind="bank.fullName" title.bind="bank.fullName">
            </div>
          </div>
          <p if.bind="search_term_bank !== '' && errorNoBank" t="home.welcome.openbanking.open_banking_bank_not_supported"></p>
        </div>
        <div id="bank-right" class="bank-right">
          <div class="circle"></div>
          <img src="${___HTML_LOADER_IMPORT_6___}"   class="" alt="welcome_experience">
        </div>
      </div>
    </section>
    
    <section id="feature" class="flex-column">
      <h5 class="little_title gray" t="home.welcome.features.features_power"></h5>
      <h2 class="title" t="home.welcome.features.features_power_title"></h2>
      <p class="sub_title gray" t="home.welcome.features.features_power_desc"></p>
      <div class="features_grid">
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_7___}">
            <h3 class="title" t="home.welcome.features.secure_storage" ></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.secure_storage_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_8___}">
            <h3 class="title" t="home.welcome.features.features_notifications"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_notifications_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_9___}">
            <h3 class="title" t="home.welcome.features.features_accounts"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_accounts_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_10___}">
            <h3 class="title" t="home.welcome.features.features_bsv"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_bsv_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_11___}">
            <h3 class="title" t="home.welcome.features.features_p2p"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_p2p_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_12___}">
            <h3 class="title" t="home.welcome.features.features_design"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_design_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_13___}">
            <h3 class="title" t="home.welcome.features.features_progress"></h3>
          </div>
          <div class="secondrow">
            <p class="description gray" t="home.welcome.features.features_progress_desc"></p>
          </div>
        </div>
        <div class="feature_card">
          <div class="firstrow">
            <img class="icon" src="${___HTML_LOADER_IMPORT_14___}">
            <h3 class="title" t="home.welcome.features.data_analysis"></h3>
          </div>
          <div class="secondrow">      
            <p class="description gray" t="home.welcome.features.data_analysis_desc"></p>
          </div>
        </div>
      </div>
    </section>

    <section id="pricing" class="flex-column">
      <h5 class="little_title gray" t="home.welcome.pricing.pricing_desc"></h5>
      <h2 class="title" t="home.welcome.pricing.pricing_title"></h2>
      <p class="sub_title gray"></p>
      <div class="subscription_cards flex-row">
        <div class="subscription flex-column">
          <div class="subscription_description flex-column">
            <h4 id="title" t="home.welcome.pricing.free.title"></h4>
            <h2 class="price" t="home.welcome.pricing.free.price"></h2>
            <p id="description" t="home.welcome.pricing.free.description"></p>
          </div>
          <div class="line flex-column"></div>
          <div class="subscription_features flex-column">
            <h5 t="home.welcome.pricing.includes"></h5>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.pricing_one_bank"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.pricing_one_wallet"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.pricing_month_list1"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.pricing_month_list2"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.pricing_month_list3"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.free.topup2"></p>
            </div>
          </div>
          <div class="flex-grow"></div>
          <button id="welcome.button.get_started.2 " class="btn-fresh btn-fresh-primary" t="home.welcome.get_started" click.delegate="getMobileOperatingSystem()"></button>
        </div>
        <div class="subscription flex-column">
          <div class="subscription_description flex-column">
            <div class="title flex-row">
              <h4 id="title" t="home.welcome.pricing.yearly.title"></h4>
              <div class="flex-grow"></div>
            </div>
            <div class="price flex-row">
              <h2 class="price" t="home.welcome.pricing.yearly.price"></h2>
              <p t="home.welcome.pricing.yearly.rate"></p>
            </div>
            <p id="description" t="home.welcome.pricing.yearly.description"></p>
          </div>
          <div class="line flex-column"></div>
          <div class="subscription_features flex-column">
            <h5 t="home.welcome.pricing.includes"></h5>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.pricing_one_bank"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.pricing_one_wallet"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.pricing_month_list1"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.pricing_month_list2"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.pricing_month_list3"></p>
            </div>
            <div class="feature flex-row">
              <img src="${___HTML_LOADER_IMPORT_15___}">
              <p t="home.welcome.pricing.yearly.topup2"></p>
            </div>
          </div>
          <div class="flex-grow"></div>
          <button id="welcome.button.get_started.4" class="btn-fresh btn-fresh-primary" t="home.welcome.get_started" click.delegate="getMobileOperatingSystem()"></button>
        </div>
      </div>
    </section>

    <section id="faq" class="flex-column">
      <h5 class="little_title gray" t="home.welcome.faq.faq"></h5>
      <h2 class="title" t="home.welcome.faq.faq_title">Have Questions? We Have Answers.</h2>
      <p class="sub_title gray" t="home.welcome.faq.description"></p>
      <div class="right flex-column">
        <div class="header_text flex-column" click.delegate="showContent('what_is_banka')">
          <div class="question_container flex-row">
            <h1 t="home.welcome.faq.faq_banka"></h1>
            <div class="flex-grow"></div>
            <p>
              <i class="fas fa-angle-down" class.bind="content1 == true ? 'rotate' : ''"></i>
            </p>
          </div>
          <div class="content open_by_default" ref="what_is_banka_content">
            <span class="gray" t="home.welcome.commitment.commitment_desc"></span>
            <span class="gray" t="home.welcome.commitment.commitment_desc_2"></span>
            <span class="gray" t="home.welcome.commitment.commitment_desc_3"></span>
          </div>
        </div>
        <div class="header_text flex-column" click.delegate="showContent('multi_account')">
          <div class="question_container flex-row">
            <h1 t="home.welcome.faq.faq_account">
              Can I add multiple accounts ?
            </h1>
            <div class="flex-grow"></div>
            <p>
              <i class="fas fa-angle-down" class.bind="content2 == true ? 'rotate' : ''"></i>
            </p>
          </div>
          <div class="content" ref="multi_account_content">
            <span class="gray" t="home.welcome.faq.faq_account_desc"></span>.
          </div>
        </div>
        <div class="header_text flex-column" click.delegate="showContent('charge')">
          <div class="question_container flex-row">
            <h1 t="home.welcome.faq.faq_charge">
              Why do we charge ?
            </h1>
            <div class="flex-grow"></div>
            <p>
              <i class="fas fa-angle-down" class.bind="content3 == true ? 'rotate' : ''"></i>
            </p>
          </div>
          <div class="content" ref="charge_content">
            <span class="gray" t="home.welcome.faq.faq_charge_desc"></span>
          </div>
        </div>
      </div>

    </section>

    <section id="pwa" class="flex-column">
      <div class="flex-row">
        <div id="pwa-left" class="pwa-left flex-column">
          <div class="circle"></div>
          <h5 class="little_title gray" t="home.welcome.download.subtitle"></h5>
          <h2 class="title" t="home.welcome.download.title">Download MyBanka Now!</h2>
          <p class="sub_title gray" t="home.welcome.download.description">Add our Progressive Web App to Your Home Screen for Seamlesss Access.</p>
          <button id="welcome.button.download"class="btn-fresh btn-fresh-primary" t="home.welcome.download.start_short" click.delegate="getMobileOperatingSystem()"><span>Start MyBanka Now!</span></button>
        </div>
        <div id="pwa-right" class="pwa-right flex-column">
          <img src="${___HTML_LOADER_IMPORT_16___}">
        </div>
      </div>
    </section>
    <footer-bottom></footer-bottom>
  </section>
</template>
</section>
</template>
`;
// Exports
export default code;