// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./confirmed.scss"></require>
  <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
  <section id="welcome_confirmed" class="flex-column">
    <div id="header" class="flex-row">
      <img click.delegate="router.navigateBack()" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="welcome_confirmed1"></h1>
    </div>
    <div class="flex-grow"></div>
    <animated-checkmark></animated-checkmark>
    <div class="flex-grow"></div>
    <div id="mailInfos" class="column">
      <p id="weSentYouAMail">
        <span i18n="welcome_confirmed2"></span><br>
        <br>
        <span i18n="[html]welcome_confirmed3"></span>
      </p>
    </div>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToLogin()"> <span i18n="welcome_confirmed4"></span></button>
    <div class="spacer-32"></div>
  </section>
</template>
`;
// Exports
export default code;