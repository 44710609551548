// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#transactions {
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 1rem;
  width: 100%;
}
section#transactions div#header {
  padding-bottom: 1rem;
  margin: 0 !important;
}
section#transactions div#header i {
  padding-left: 0.4rem;
}
section#transactions div#header div.dropdown {
  position: relative;
}
section#transactions div#header div.dropdown ul {
  background: #0f131a;
  border: 1px solid var(--stroke-dark-base, #2F3340);
  border-radius: 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  margin: 0rem;
  min-width: 10rem;
  overflow: hidden;
  padding: 0rem;
  position: absolute;
  right: 1rem;
  top: 2rem;
  z-index: 1;
}
section#transactions div#header div.dropdown ul li {
  cursor: pointer;
  list-style: none;
  margin: 0rem;
  padding: 0.5rem 1rem;
}
section#transactions div#header div.dropdown ul li:first-child {
  padding-top: 1rem !important;
}
section#transactions div#header div.dropdown ul li:last-child {
  padding-bottom: 1rem !important;
}
section#transactions div#header div.dropdown ul li:hover {
  background: var(--background-dark-base-third, #1F2330);
}
section#transactions div#header div.dropdown ul.show {
  display: block;
}
section#transactions loader-ring {
  color: rgb(255, 255, 255);
  margin: auto;
  height: 7rem;
  width: 7rem;
}
section#transactions transaction-component, section#transactions transaction-bsv-component {
  flex-grow: 1;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/home/transactions/transactions.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAIF;AAFE;EACE,oBAAA;EACA,oBAAA;AAIJ;AAFI;EACE,oBAAA;AAIN;AADI;EACE,kBAAA;AAGN;AADM;EACE,mBD4BiB;EC3BjB,kDAAA;EACA,mBAAA;EACA,+CAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;AAGR;AADQ;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,oBAAA;AAGV;AAAQ;EACE,4BAAA;AAEV;AACQ;EACE,+BAAA;AACV;AAEQ;EACE,sDAAA;AAAV;AAIM;EACE,cAAA;AAFR;AAOE;EACE,yBDrCU;ECsCV,YAAA;EACA,YAAA;EACA,WAAA;AALJ;AAQE;EACE,YAAA;EACA,WAAA;AANJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
