// Module
var code = `<template>
    <require from="./success.scss"></require>
    <require from="../../../../components/animated_checkmark/animated_checkmark"></require>
    <section id="install_success" class="flex-column">
      <div id="header" class="flex-row">
        <h1 i18n="home.welcome.install.install_success1">Installation réussie</h1>
      </div>
      <div class="flex-grow"></div>
      <animated-checkmark></animated-checkmark>
      <div class="flex-grow"></div>
      <p i18n="home.welcome.install.install_success2">Vous pouvez maintenant quitter cette page afin de lancer l'application</p>
      <div class="flex-grow"></div>
    </section>
  </template>
`;
// Exports
export default code;