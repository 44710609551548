// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#transaction_comments {
  align-items: center;
  height: 100%;
  margin: auto;
  padding: 1rem;
  width: 100%;
}
section#transaction_comments div#header {
  padding-bottom: 1rem;
  margin: 0 !important;
}
section#transaction_comments div#header i {
  padding-left: 0.4rem;
}
section#transaction_comments loader-ring {
  color: rgb(255, 255, 255);
  margin: auto;
  height: 7rem;
  width: 7rem;
}
section#transaction_comments transaction-component, section#transaction_comments transaction-bsv-component {
  flex-grow: 1;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/pages/home/transactions/comment/comment.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,mBAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAIF;AAFE;EACE,oBAAA;EACA,oBAAA;AAIJ;AAFI;EACE,oBAAA;AAIN;AAAE;EACE,yBDKU;ECJV,YAAA;EACA,YAAA;EACA,WAAA;AAEJ;AACE;EACE,YAAA;EACA,WAAA;AACJ","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
