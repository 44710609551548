// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/arrow_left.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/not_found.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./not-found.scss"></require>
  <section id="not_found" class="flex-column">
    <div id="header" class="flex-row">
      <img id="thumbnail" click.delegate="router.navigateToRoute('home')" src="${___HTML_LOADER_IMPORT_0___}">
      <h1 i18n="home.not_found.1"></h1>
    </div>
    <div class="flex-grow"></div>
    <img src="${___HTML_LOADER_IMPORT_1___}">
    <div class="flex-grow"></div>
      <h3 i18n="home.not_found.2"></h3>
    <div class="flex-grow"></div>
    <button class="btn btn-primary" click.delegate="goToWelcome()">
      <span i18n="home.goBackHome"></span>
    </button>
    <div class="spacer-default"></div>
  </section>`;
// Exports
export default code;