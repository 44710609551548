import { Router } from 'aurelia-router';
import { autoinject } from 'aurelia-framework';

@autoinject
export class notFound {
    constructor(private router: Router) {

    }
    goToWelcome() {
        this.router.navigateToRoute('welcome')
    }
}