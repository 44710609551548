import { Router } from 'aurelia-router';
import { autoinject } from "aurelia-dependency-injection";

@autoinject
export class DownloadIphone {
    private step: number = 1
    private minSteps : number = 2
    private maxSteps : number = 4
    constructor( private router: Router){}
  
    goToNextImage() {
      if(this.step < this.maxSteps) {
        this.step = this.step	+ 1
      }
      else {
        this.step = 1
      }
    }

    goToPreviousImage() {
      if(this.step < this.maxSteps) {
        this.step = this.step	- 1
      }
      else {
        this.router.navigateToRoute("welcome")
      }
    }
  }
