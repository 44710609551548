import { SingletonService } from "singleton";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import ENDPOINTS from "endpoints";
import { BankaService } from "services/banka.service";
import { WalletsManager, WalletInfo } from "services/WalletManager.service";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyTransactionsModel } from "components/models/YapilyModels/YapilyTransactionsModel";
import { connectTo } from "aurelia-store";

@connectTo()
@autoinject
export class TransactionComments {

  constructor(
    private authService: AuthService,
    private router: Router,
    private singleton: SingletonService,
    private yapilyhttpclient: YapilyHttpClient,
    private bankaService: BankaService,
    private walletManager: WalletsManager
  ) {
  }
}
